<template>
  <div @click="loadAppsIsShow">
    <AppsMenuDesktop v-if="mdAndUp" v-model="openAppsMenu" :links="links.length > 0 ? links : []"
      @routerTo="handleSelectRouterTo" />
    <AppsMenuMobile v-else v-model="openAppsMenu" :links="links.length > 0 ? links : []"
      @routerTo="handleSelectRouterTo" />
  </div>
</template>

<script setup lang="ts">
  import { ref } from "vue";
  import { useDisplay } from "vuetify/lib/framework.mjs";
  import AppsMenuDesktop from "./AppsMenuDesktop.vue";
  import AppsMenuMobile from "./AppsMenuMobile.vue";
  import { getApps } from "@/api/resources/oneResource";
  import { useOneStore } from "@/store/OneStore";

  import NexusLogo from "@/assets/logos/logo_nexus.svg";
  import ShieldLogo from "@/assets/logos/logo_shield.svg";
  import { customEvents } from "@clearsale/one-lib-events";
  import { useRouter } from "vue-router";

  const { mdAndUp } = useDisplay();

  const model = defineModel({ default: false });
  const openAppsMenu = ref(model);

  const loading = ref(true);

  const allApps = [
    {
      id: "Apps",
      label: "One",
      routerName: "OneView",
      order: 1,
      subLinks: [],
      description: "",
    },
    {
      id: "Apps",
      label: "Shield",
      routerName: "ShieldView",
      order: 2,
      img: ShieldLogo,
      subLinks: [],
      description: "Intelligence in fraud analysis",
    },
    {
      id: "Apps",
      label: "Nexus",
      routerName: "NexusView",
      order: 3,
      img: NexusLogo,
      subLinks: [],
      description: "Fraud analysis visualization",
    },
    {
      id: "Apps",
      label: "Portal",
      routerName: "PortalView",
      order: 3,
      subLinks: [],
      description: "Portal ClearSale",
    },
  ];

  const links = ref<NewMenu.Link[]>([]);

  const { loadApps } = useOneStore();

  const routerMap: AppsMenu.SelectRouter = {
    NexusView: "/nexus",
    ShieldView: "/shield",
    OneView: "/app",
    PortalView: "/portal",
  };

  function handleSelectRouterTo(link: NewMenu.Link) {


    if (window.location.href.includes("portal")) {
      window.location.href = routerMap[link.routerName as AppsMenu.AppView_Type]
      return
    }
    customEvents.routerEvent.dispatch(
      routerMap[link.routerName as AppsMenu.AppView_Type]
    );
  }

  const router = useRouter()


  router.beforeEach((to, from, next) => {

    if (to.fullPath.includes("portal") && from.fullPath.includes("portal")) {
      next()
      return
    }

    if (from.fullPath.includes("portal") && !to.fullPath.includes("portal")) {
      window.location.href = to.fullPath
      return
    }
    next()
  });

  function loadAppsIsShow() {
    getApps()
      .then((res) => {
        const appsAllowed = allApps.filter((app) => {
          return res.results.includes(app.label)
        }
        );
        links.value = appsAllowed;

        loadApps(allApps);
      })
      .finally(() => {
        loading.value = false;
      });
  }
</script>
