import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "cs__menu w-100 d-flex flex-column"
}
const _hoisted_3 = { class: "d-flex flex-column rounded-lg overflow-y-auto" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "d-flex justify-space-between flex-column w-100" }
const _hoisted_6 = { class: "d-flex ga-2 w-100" }
const _hoisted_7 = { class: "d-flex w-100 align-center" }
const _hoisted_8 = {
  style: {"max-width":"250px"},
  class: "w-100"
}
const _hoisted_9 = { style: {"font-size":"16px"} }
const _hoisted_10 = {
  style: {"font-size":"24px"},
  class: "text-cs_primary"
}
const _hoisted_11 = { style: {"font-size":"16px"} }
const _hoisted_12 = {
  key: 1,
  class: "bg-surface-variant d-flex flex-column py-4 ga-4"
}
const _hoisted_13 = { class: "d-flex justify-space-between flex-column w-100" }
const _hoisted_14 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_15 = { class: "d-flex ga-2 w-100" }
const _hoisted_16 = { class: "d-flex flex-column w-100" }
const _hoisted_17 = {
  style: {"font-size":"16px"},
  class: "px-4"
}
const _hoisted_18 = { class: "w-100 d-flex flex-column ga-5" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = {
  style: {"max-width":"250px"},
  class: "w-100"
}
const _hoisted_21 = {
  style: {"font-size":"16px","font-weight":"bolder"},
  class: "text-cs_primary"
}
const _hoisted_22 = { style: {"font-size":"16px"} }

import { customEvents } from "@clearsale/one-lib-events"
  import { computed, nextTick, ref } from "vue";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'CustomNotification',
  setup(__props) {

  customEvents.notificationEvent.loaded.listener(async (data: CustomEvent) => {
    notifications.value = data.detail
    await nextTick()
  })

  const hasNotification = ref(false)
  const notifications = ref<any[]>([])
  const notificationAgrouped = ref(true)

  const hasUnreadNotifications = computed(() => {
    return notifications.value.some(notification => !notification.read)
  })

  const countUnreadNotifications = computed(() => {
    return notifications.value.filter(notification => !notification.read).length
  })

  function handleOpenNotification() {
    hasNotification.value = true
    customEvents.notificationEvent.opened.dispatch({ message: "open" })
  }

  function handleCloseNotification() {
    hasNotification.value = false
    customEvents.notificationEvent.closed.dispatch({ message: "close" })
  }

  function handleClickNotification(notification: any) {
    customEvents.notificationEvent.clicked.dispatch(notification)
  }

  const notificationsSortedByDate = computed(() => {
    return [...notifications.value].sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    })
  })

  const notificationsGroupedByDate = computed(() => {
    return notificationsSortedByDate.value.reduce((acc, notification) => {
      const date = new Date(notification.createdAt).toISOString().split('T')[0]; // Formata a data como YYYY-MM-DD
      if (!acc[date]) {
        acc[date] = {
          createdAtFormatted: notification.createdAtFormatted,
          notifications: []
        };
      }
      acc[date].notifications.push(notification);
      return acc;
    }, {} as Record<string, { createdAtFormatted: string, notifications: any[] }>);
  });

  const sortedDates = computed(() => {
    return Object.keys(notificationsGroupedByDate.value).sort((a, b) => {
      return new Date(b).getTime() - new Date(a).getTime();
    });
  });


return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_hover = _resolveComponent("v-hover")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (notifications.value.length)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_btn, {
          icon: "",
          variant: "text",
          color: "cs_surface_variant",
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (hasNotification.value ? handleCloseNotification() : handleOpenNotification()), ["stop"]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_badge, {
              color: "primary",
              content: countUnreadNotifications.value,
              modelValue: hasUnreadNotifications.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((hasUnreadNotifications).value = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  icon: "mdi-bell-outline",
                  class: "text-cs_on_surface_variant"
                })
              ]),
              _: 1
            }, 8, ["content", "modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (hasNotification.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    (!notificationAgrouped.value)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(notificationsSortedByDate.value, (notification) => {
                          return (_openBlock(), _createBlock(_component_v_hover, {
                            key: notification.id
                          }, {
                            default: _withCtx(({ isHovering, props }) => [
                              _createElementVNode("div", _mergeProps({
                                onClick: ($event: any) => (handleClickNotification(notification)),
                                ref_for: true
                              }, props, {
                                class: ["pa-4 bg-surface-variant cursor-pointer d-flex flex-column", [
                    { 'bg-cs_surface_container': isHovering },
                  ]]
                              }), [
                                _createElementVNode("div", _hoisted_5, [
                                  _createElementVNode("div", _hoisted_6, [
                                    _createElementVNode("div", _hoisted_7, [
                                      _createElementVNode("div", _hoisted_8, [
                                        _createElementVNode("span", _hoisted_9, _toDisplayString(notification.createdAtFormatted), 1),
                                        _createElementVNode("h1", _hoisted_10, _toDisplayString(notification.title), 1),
                                        _createElementVNode("p", _hoisted_11, _toDisplayString(notification.description), 1)
                                      ]),
                                      _createVNode(_component_v_icon, {
                                        icon: notification.icon,
                                        class: "text-cs_on_surface_variant"
                                      }, null, 8, ["icon"])
                                    ])
                                  ])
                                ])
                              ], 16, _hoisted_4)
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedDates.value, (date, index) => {
                            return (_openBlock(), _createElementBlock("div", { key: date }, [
                              _createElementVNode("div", _hoisted_13, [
                                (index > 1)
                                  ? (_openBlock(), _createElementBlock("hr", _hoisted_14))
                                  : _createCommentVNode("", true),
                                _createElementVNode("div", _hoisted_15, [
                                  _createElementVNode("div", _hoisted_16, [
                                    _createElementVNode("span", _hoisted_17, _toDisplayString(notificationsGroupedByDate.value[date].createdAtFormatted), 1),
                                    _createElementVNode("div", _hoisted_18, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((notificationsGroupedByDate.value[date].notifications), (notification) => {
                                        return (_openBlock(), _createBlock(_component_v_hover, {
                                          key: notification.id
                                        }, {
                                          default: _withCtx(({ isHovering, props }) => [
                                            _createElementVNode("div", _mergeProps({
                                              onClick: _withModifiers(($event: any) => (handleClickNotification(notification)), ["stop"]),
                                              class: "d-flex ga-2 align-center cursor-pointer px-4",
                                              ref_for: true
                                            }, props, {
                                              class: [
                                { 'bg-cs_surface_container': isHovering },
                              ]
                                            }), [
                                              _createElementVNode("div", _hoisted_20, [
                                                _createElementVNode("h1", _hoisted_21, _toDisplayString(notification.title), 1),
                                                _createElementVNode("p", _hoisted_22, _toDisplayString(notification.description), 1)
                                              ]),
                                              _createVNode(_component_v_icon, {
                                                icon: notification.icon,
                                                class: "text-cs_primary"
                                              }, null, 8, ["icon"])
                                            ], 16, _hoisted_19)
                                          ]),
                                          _: 2
                                        }, 1024))
                                      }), 128))
                                    ])
                                  ])
                                ])
                              ])
                            ]))
                          }), 128))
                        ]))
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])), [
        [_directive_click_outside, handleCloseNotification]
      ])
    : _createCommentVNode("", true)
}
}

})