import { BASE_URL } from "@/api/resources/oneResource";
import { http, HttpResponse } from "msw";

export const handlersHTTP = [
  http.get(BASE_URL + '/one/users/apps', async () => {
    return HttpResponse.json({
      results: ["One", "Shield", "Nexus", "Portal"]
    })
  })
];
