import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { onMounted, ref, watch } from "vue";
  import CustomMenuDesktop from "../CustomMenuDesktop/CustomMenuDesktop.vue";
  import CustomMenuMobile from "../CustomMenuMobile/CustomMenuMobile.vue";
  import { useDisplay, useTheme } from "vuetify/lib/framework.mjs";
  import { timezoneCityCountry } from "./timezone";
  import { useI18n } from "vue-i18n";
  import { label } from "@/plugins/locales/global";
  import { lib } from "@clearsale/one-lib-auth";
  import { useRouter } from "vue-router";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'UserMenu',
  props: {
    "modelValue": { default: false },
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

  const { mdAndUp } = useDisplay();

  const { t, locale } = useI18n();
  const model = _useModel(__props, "modelValue");
  const { global } = useTheme();

  const links = ref<NewMenu.Link[]>([]);

  function toggleTheme(theme: string) {
    global.name.value = theme !== "dark" ? "light" : "dark";
  }

  function selectTheme(theme: string) {
    const themeName = theme.split("/")[1];
    localStorage.setItem("theme", themeName);
    toggleTheme(themeName);
  }

  function selectTimezone(timezone: string) {
    const timezoneName = timezone.split("/")[1];
    localStorage.setItem("timezone", timezoneName);
  }

  function selectLanguage(language: string) {
    const newLang = language.split("/")[1];
    localStorage.setItem("language", newLang);
    locale.value = newLang;
  }

  const params = {
    authFlow: process.env.VUE_APP_AUTH_FLOW ?? "",
    authRedirectOne: process.env.VUE_APP_AUTH_REDIRECT_URI_ONE ?? "",
    authTenant: process.env.VUE_APP_AUTH_TENANT ?? "",
    authUrl: process.env.VUE_APP_AUTH_URL ?? "",
  };

  function handleSelectLink(link: NewMenu.Link) {
    if (link.id.includes("theme")) {
      selectTheme(link.id);
    }

    if (link.id.includes("timezone")) {
      selectTimezone(link.id);
    }

    if (link.id.includes("language")) {
      selectLanguage(link.id);
    }

    if (link.id.includes("auth")) {
      lib.Authenticate.logout(params);
    }
  }

  function handleSelectRouterTo(link: NewMenu.Link) {
    console.log("ROUTER TO::", link);
  }
  const urlHasClientPortal = ref(window.location.href.includes("portal"));
  function updateLink() {
    links.value = [
      {
        id: "user",
        label: lib.Authenticate.getTokenUserAccessByOne()?.name ?? "",
        routerName: "",
        order: 1,
        subLinks: [],
        img: {
          name: "https://cdn-icons-png.flaticon.com/512/1144/1144709.png",
          size: "40px",
        },
      },
      {
        id: "language",
        label: t(label.UserMenu.language.title),
        routerName: "",
        order: 2,
        subLinks: [
          {
            id: "language",
            label: t(label.UserMenu.language.title),
            order: 1,
            routerName: "",
            subLinks: [],
          },
          {
            id: "language/pt-BR",
            label: t(label.UserMenu.language.portuguese),
            routerName: "",
            order: 2,
            subLinks: [],
          },
          {
            id: "language/en-US",
            label: t(label.UserMenu.language.english),
            routerName: "",
            order: 3,
            subLinks: [],
          },
          {
            id: "language/es",
            label: t(label.UserMenu.language.spanish),
            routerName: "",
            order: 4,
            subLinks: [],
          },
        ],
      },
      {
        id: "timezone",
        label: t(label.UserMenu.timezone),
        routerName: "",
        order: 3,
        subLinks: [
          {
            id: "timezone",
            label: t(label.UserMenu.timezone),
            order: 1,
            routerName: "",
            subLinks: [],
          },
          ...timezoneCityCountry.utc_timezones.map((item) => ({
            id: "timezone/" + item.utc_offset,
            label: `(${item.utc_offset}) ${item.city}`,
            order: 2,
            routerName: "",
            subLinks: [],
          })),
        ],
      },
      {
        id: "theme",
        label: t(label.UserMenu.theme.title),
        routerName: "",
        order: 4,
        subLinks: [
          {
            id: "theme",
            label: t(label.UserMenu.theme.title),
            order: 1,
            routerName: "",
            subLinks: [],
          },
          {
            id: "theme/light",
            label: t(label.UserMenu.theme.light),
            routerName: "",
            order: 2,
            subLinks: [],
            icon: {
              name: "",
              size: "",
            },
          },
          {
            id: "theme/dark",
            label: t(label.UserMenu.theme.dark),
            routerName: "",
            order: 3,
            subLinks: [],
            icon: {
              name: "",
              size: "",
            },
          },
        ],
      },

      {
        id: "separator",
        label: "",
        order: 5,
        subLinks: [],
        routerName: "",
      },
      {
        id: "auth",
        label: t(label.UserMenu.signout),
        routerName: "",
        order: 6,
        subLinks: [],
        className: "text-cs_primary",
        icon: {
          name: "mdi-logout",
        },
      },
    ];
  }

  function updateLinkWhenClientPortal() {

    links.value = [
      {
        id: "user",
        label: lib.Authenticate.getTokenUserAccessByOne()?.name ?? "",
        routerName: "",
        order: 1,
        subLinks: [],
        img: {
          name: "https://cdn-icons-png.flaticon.com/512/1144/1144709.png",
          size: "40px",
        },
      },
      {
        id: "language",
        label: t(label.UserMenu.language.title),
        routerName: "",
        order: 2,
        subLinks: [
          {
            id: "language",
            label: t(label.UserMenu.language.title),
            order: 1,
            routerName: "",
            subLinks: [],
          },
          {
            id: "language/en-US",
            label: t(label.UserMenu.language.english),
            routerName: "",
            order: 3,
            subLinks: [],
          },
          {
            id: "language/es",
            label: t(label.UserMenu.language.spanish),
            routerName: "",
            order: 4,
            subLinks: [],
          },
        ],
      },
      {
        id: "separator",
        label: "",
        order: 5,
        subLinks: [],
        routerName: "",
      },
      {
        id: "auth",
        label: t(label.UserMenu.signout),
        routerName: "",
        order: 6,
        subLinks: [],
        className: "text-cs_primary",
        icon: {
          name: "mdi-logout",
        },
      },
    ];

  }
  watch(locale, () => urlHasClientPortal.value ? updateLinkWhenClientPortal() : updateLink());
  onMounted(() => urlHasClientPortal.value ? updateLinkWhenClientPortal() : updateLink());

  const router = useRouter()

  router.beforeEach((to, from, next) => {
    urlHasClientPortal.value = to.path.includes("portal");

    if (urlHasClientPortal.value) {
      updateLinkWhenClientPortal();
      global.name.value = "light";
      localStorage.setItem("theme", "light");
    } else {
      updateLink();
    }
    next();
  });

return (_ctx: any,_cache: any) => {
  return (_unref(mdAndUp))
    ? (_openBlock(), _createBlock(CustomMenuDesktop, {
        key: 0,
        modelValue: model.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
        links: links.value,
        onSelectLink: handleSelectLink,
        onRouterTo: handleSelectRouterTo
      }, null, 8, ["modelValue", "links"]))
    : (_openBlock(), _createBlock(CustomMenuMobile, {
        key: 1,
        modelValue: model.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((model).value = $event)),
        links: links.value,
        onSelectLink: handleSelectLink,
        onRouterTo: handleSelectRouterTo
      }, null, 8, ["modelValue", "links"]))
}
}

})