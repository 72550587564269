<template>
  <header class="bg-cs_background">
    <div class="d-flex align-center ga-8 w-100 ma-auto px-4" style="position: relative" id="menu">
      <slot name="logo" v-if="showLogo">
        <img :src="getImageByApp" :alt="getAltImgByApp" class="logo__img" height="48px" @click="handleLogoClick" />
      </slot>
      <div class="d-flex align-center justify-space-between w-100">
        <div>
          <slot>
            <div></div>
          </slot>
        </div>
        <nav>
          <slot name="nav">
            <div class="d-flex align-center">
              <slot name="links">
                <CustomNotification />
                <AppsMenu v-model="appsMenu" />
                <UserMenu v-model="userMenu" />
              </slot>
            </div>
          </slot>
        </nav>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
  import { useRouter } from "vue-router";
  import { computed, onMounted, ref, watch } from "vue";

  import UserMenu from "./UserMenu/UserMenu.vue";
  import AppsMenu from "./AppsMenu/AppsMenu.vue";

  import imgShield from "@/assets/logo_shield.svg";
  import imgClearsale from "@/assets/logo_mobile.png";
  import imgNexusLogo from "@/assets/logos/logo_nexus.svg";
  import { customEvents } from "@clearsale/one-lib-events";
  import CustomNotification from "./CustomNotification/CustomNotification.vue";


  const router = useRouter();

  withDefaults(defineProps<{ showLogo?: boolean }>(), { showLogo: true });
  const appsMenu = ref(false);
  const userMenu = ref(false);

  const routerMap = {
    portal: {
      img: imgClearsale,
      url: "/portal",
      altImg: "Clearsale Logo",
    },
    nexus: {
      img: imgNexusLogo,
      url: "/nexus",
      altImg: "Nexus Logo",
    },
    shield: {
      img: imgShield,
      url: "/shield",
      altImg: "Shield Logo",
    },
    app: {
      img: imgClearsale,
      url: "/app",
      altImg: "Clearsale Logo",
    },
  } as const;

  const pathSplit = ref<keyof typeof routerMap>("app");

  const redirectToRouteByApp = computed(
    () => routerMap[(pathSplit.value as keyof typeof routerMap) || "app"].url
  );
  const getImageByApp = computed(() => {
    return routerMap[(pathSplit.value as keyof typeof routerMap) || "app"].img;
  });

  const getAltImgByApp = computed(
    () => routerMap[(pathSplit.value as keyof typeof routerMap) || "app"].altImg
  );

  const handleLogoClick = () => {
    if (window.scrollY > 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      customEvents.routerEvent.dispatch(redirectToRouteByApp.value);
    }
  };

  router.beforeEach((_, __, next) => {
    loadSplitPathApplicationHistory();
    next();
  });
  function loadSplitPathApplicationHistory() {
    const currentState = window.history.state;

    if (currentState && typeof currentState.current === "string") {
      const [, applicationSplitPath] = currentState.current.split("/");

      if (applicationSplitPath && typeof applicationSplitPath === "string") {
        const [cleanApplicationSplitPath] = applicationSplitPath.split("?");

        pathSplit.value = cleanApplicationSplitPath as keyof typeof routerMap;
      }
    }
  }
  onMounted(() => {
    loadSplitPathApplicationHistory();
  });

  watch(appsMenu, () => {
    if (appsMenu.value) {
      userMenu.value = false;
    }
  });

  watch(userMenu, () => {
    if (userMenu.value) {
      appsMenu.value = false;
    }
  });
</script>

<style lang="scss" scoped>
  .logo__img:hover {
    cursor: pointer;
  }
</style>
