<template>
  <div v-click-outside="handleCloseNotification" v-if="notifications.length">
    <v-btn icon variant="text" color="cs_surface_variant"
      @click.stop="hasNotification ? handleCloseNotification() : handleOpenNotification()">
      <v-badge color="primary" :content="countUnreadNotifications" v-model="hasUnreadNotifications">
        <v-icon icon="mdi-bell-outline" class="text-cs_on_surface_variant"></v-icon>
      </v-badge>
    </v-btn>
    <Transition name="fade">
      <div class="cs__menu w-100 d-flex flex-column" v-if="hasNotification">
        <div class="d-flex flex-column rounded-lg overflow-y-auto">
          <template v-if="!notificationAgrouped">
            <v-hover v-for="notification in notificationsSortedByDate" :key="notification.id">
              <template v-slot:default="{ isHovering, props }">
                <div @click="handleClickNotification(notification)" v-bind="props"
                  class="pa-4 bg-surface-variant cursor-pointer d-flex  flex-column" :class="[
                    { 'bg-cs_surface_container': isHovering },
                  ]">
                  <div class="d-flex  justify-space-between flex-column  w-100">
                    <div class="d-flex  ga-2  w-100">
                      <div class="d-flex  w-100 align-center">
                        <div style="max-width: 250px;" class="w-100">
                          <span style="font-size: 16px;">
                            {{ notification.createdAtFormatted }}
                          </span>
                          <h1 style="font-size: 24px;" class="text-cs_primary">
                            {{ notification.title }}
                          </h1>
                          <p style="font-size: 16px;">
                            {{ notification.description }}
                          </p>

                        </div>
                        <v-icon :icon="notification.icon" class="text-cs_on_surface_variant"></v-icon>
                      </div>

                    </div>

                  </div>
                </div>
              </template>
            </v-hover>
          </template>
          <template v-else>
            <div class="bg-surface-variant  d-flex flex-column py-4 ga-4">
              <div v-for="(date, index) in sortedDates" :key="date">
                <div class="d-flex justify-space-between flex-column w-100">
                  <hr v-if="index > 1" class="mb-4">
                  <div class="d-flex ga-2 w-100">
                    <div class="d-flex  flex-column w-100">
                      <span style="font-size: 16px;" class="px-4">
                        {{ notificationsGroupedByDate[date].createdAtFormatted }}
                      </span>
                      <div class="w-100 d-flex flex-column ga-5">
                        <v-hover v-for="notification in (notificationsGroupedByDate[date].notifications)"
                          :key="notification.id">
                          <template v-slot:default="{ isHovering, props }">
                            <div @click.stop="handleClickNotification(notification)"
                              class="d-flex ga-2 align-center cursor-pointer px-4" v-bind="props" :class="[
                                { 'bg-cs_surface_container': isHovering },
                              ]">
                              <div style="max-width: 250px;" class="w-100">
                                <h1 style="font-size: 16px; font-weight: bolder;" class="text-cs_primary">
                                  {{ notification.title }}
                                </h1>
                                <p style="font-size: 16px;">
                                  {{ notification.description }}
                                </p>
                              </div>
                              <v-icon :icon="notification.icon" class="text-cs_primary"></v-icon>
                            </div>
                          </template>
                        </v-hover>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </Transition>

  </div>
</template>

<script setup lang="ts">
  import { customEvents } from "@clearsale/one-lib-events"
  import { computed, nextTick, ref } from "vue";

  customEvents.notificationEvent.loaded.listener(async (data: CustomEvent) => {
    notifications.value = data.detail
    await nextTick()
  })

  const hasNotification = ref(false)
  const notifications = ref<any[]>([])
  const notificationAgrouped = ref(true)

  const hasUnreadNotifications = computed(() => {
    return notifications.value.some(notification => !notification.read)
  })

  const countUnreadNotifications = computed(() => {
    return notifications.value.filter(notification => !notification.read).length
  })

  function handleOpenNotification() {
    hasNotification.value = true
    customEvents.notificationEvent.opened.dispatch({ message: "open" })
  }

  function handleCloseNotification() {
    hasNotification.value = false
    customEvents.notificationEvent.closed.dispatch({ message: "close" })
  }

  function handleClickNotification(notification: any) {
    customEvents.notificationEvent.clicked.dispatch(notification)
  }

  const notificationsSortedByDate = computed(() => {
    return [...notifications.value].sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    })
  })

  const notificationsGroupedByDate = computed(() => {
    return notificationsSortedByDate.value.reduce((acc, notification) => {
      const date = new Date(notification.createdAt).toISOString().split('T')[0]; // Formata a data como YYYY-MM-DD
      if (!acc[date]) {
        acc[date] = {
          createdAtFormatted: notification.createdAtFormatted,
          notifications: []
        };
      }
      acc[date].notifications.push(notification);
      return acc;
    }, {} as Record<string, { createdAtFormatted: string, notifications: any[] }>);
  });

  const sortedDates = computed(() => {
    return Object.keys(notificationsGroupedByDate.value).sort((a, b) => {
      return new Date(b).getTime() - new Date(a).getTime();
    });
  });

</script>

<style scoped lang="scss">
  .cs__menu {
    max-width: 330px;
    position: absolute;
    z-index: 999;
    right: 16px;
    top: 48px;
    transition: opacity 0.3s;
    max-height: 558px;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  // estilize a barra lateral do scroll da lista de notificações do browser
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }


</style>
